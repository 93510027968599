import React from 'react';

type HoverProjectColorProps = {
    activeHoverColor?: any;
};

export const HoverProjectColor: React.FC<HoverProjectColorProps> = ({ activeHoverColor }) => {
    return (
        <div
            className="projectHoverWrapper"
            style={{
                backgroundColor: activeHoverColor ? activeHoverColor : '#fff',
            }}
        ></div>
    );
};
