import { Link } from 'gatsby';
import React, { useCallback } from 'react';

type TopMenuListProjectProps = {
    project?: any;
    setActiveHoverColor: any;
    setActiveBackgroundImage: any;
    activeTitle: string;
    setActiveTitle: any;
};

export const TopMenuListProject: React.FC<TopMenuListProjectProps> = ({
    project,
    setActiveHoverColor,
    setActiveBackgroundImage,
    activeTitle,
    setActiveTitle,
}) => {
    const mouseEnterActions = useCallback(() => {
        setActiveHoverColor(project.data?.project_color);
        setActiveBackgroundImage(project.data?.project_thumbnail?.gatsbyImageData);
        setActiveTitle(project.data.title?.text);
    }, [
        project.data?.project_color,
        project.data?.project_thumbnail?.gatsbyImageData,
        project.data.title?.text,
        setActiveBackgroundImage,
        setActiveHoverColor,
        setActiveTitle,
    ]);

    if (!project) return null;

    return (
        <>
            <div className="listProjectWrapper" onMouseEnter={mouseEnterActions}>
                <Link to={project.url} className="projectThumbnailUrl">
                    <p className={`${activeTitle === project.data.title?.text ? 'active' : ''}`}>
                        {project.data.title?.text}
                    </p>
                </Link>
            </div>
        </>
    );
};
