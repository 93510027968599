import * as React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { PrismicRichText, SliceZone } from '@prismicio/react';
import { useEffect } from 'react';
import { Layout } from '../components/Layout';
import { components } from '../slices';
import { Tween } from 'react-gsap';
import { ProjectHeaderFullScreen } from '../components/ProjectHeaderFullScreen';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTopMenuControllerContext } from '../provider/TopMenuContextProvider';

type PageTemplateProps = {
    data?: {
        prismicPage?: any;
        prismicTopMenu?: any;
        allPrismicProject?: any;
    };
};

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
    const breakpoints = useBreakpoint();
    const { setWhiteHeader } = useTopMenuControllerContext();

    setWhiteHeader(false);

    useEffect(() => {
        document.body.style.overflow = 'visible';
    }, []);

    if (!data) return null;

    const pageContent = data.prismicPage;
    const page = pageContent.data || {};

    const { lang, type, url } = pageContent;
    const alternateLanguages = pageContent.alternate_languages || [];
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    };
    const topMenu = data.prismicTopMenu || {};
    const projectsData = data.allPrismicProject || {};

    return (
        <Layout topMenu={topMenu.data} projectsData={projectsData} activeDocMeta={activeDoc} forceWhiteHeader={false}>
            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={1} ease="Power1.easeInOut">
                <div className="pageWrapper">
                    <div className={`projectHeaderWrapper ${breakpoints.sm ? 'mobile' : ''}`}>
                        <ProjectHeaderFullScreen
                            videoUrl={page.page_header_video_link.text}
                            fullScreenImage={page.page_image_header}
                        />
                        <div className="projectInfoWrapper">
                            <h1>{page.page_title.text}</h1>
                            {page.page_header_description.richText.length > 0 && (
                                <div className="pageDescription">
                                    <PrismicRichText field={page.page_header_description.richText} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mediasWrapper pageMediasWrapper">
                        <SliceZone slices={page.body} components={components} />
                    </div>
                </div>
            </Tween>
        </Layout>
    );
};

export const query = graphql`
    query pageQuery($id: String, $lang: String) {
        prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
            _previewable
            url
            uid
            type
            id
            lang
            alternate_languages {
                id
                type
                lang
                uid
            }
            data {
                page_title {
                    text
                }
                page_image_header {
                    gatsbyImageData
                    alt
                    copyright
                }
                page_header_video_link {
                    text
                }
                page_header_description {
                    richText
                }
                body {
                    ... on PrismicSliceType {
                        id
                        slice_label
                        slice_type
                    }
                    ...PageDataBodyFullWidthImage
                    ...PageDataBodyText
                }
            }
        }
        prismicTopMenu(lang: { eq: $lang }) {
            ...TopMenuFragment
        }
        allPrismicProject(sort: { fields: data___date, order: DESC }, filter: { lang: { eq: $lang } }) {
            nodes {
                id
                uid
                url
                data {
                    title {
                        text
                    }
                    project_color
                    project_thumbnail {
                        gatsbyImageData
                        alt
                        copyright
                    }
                }
            }
        }
    }
`;

export default withPrismicPreview(PageTemplate as any);
// export default PageTemplate;
