import React, { useState } from 'react';
import { ProjectThumbnail } from './ProjectThumbnail';
import { HoverProjectColor } from './HoverProjectColor';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { PrismicRichText } from '@prismicio/react';
import { StaticImage } from 'gatsby-plugin-image';

type ProjectsProps = {
    data?: any;
    categories: any;
    homepageData?: any;
};

export const Projects: React.FC<ProjectsProps> = ({ data, categories, homepageData }) => {
    const projects = data;
    const [activeHoverColor, setActiveHoverColor] = useState('#ffffff');
    const breakpoints = useBreakpoint();
    const [isHover, setIsHover] = useState(false);
    const [isTransitionInProgress, setIsTransitionInProgress] = useState(false);
    const isHomeDescription = homepageData.homepage_description.richText[0].text ? true : false;

    if (!data) return null;

    return (
        <>
            <div className="projectsWrapper">
                <HoverProjectColor activeHoverColor={activeHoverColor} />
                {isHomeDescription && !breakpoints.sm && (
                    <div
                        className="homeDescription"
                        style={{
                            opacity: isHover || isTransitionInProgress ? '0' : '1',
                        }}
                    >
                        <PrismicRichText field={homepageData.homepage_description.richText} />
                    </div>
                )}
                <div
                    className="gridProjectsWrapper"
                    style={{
                        marginTop: isHomeDescription && !breakpoints.sm ? '80px' : '0px',
                    }}
                >
                    {projects.map((project: any) => (
                        <ProjectThumbnail
                            project={project}
                            categories={categories}
                            key={project.id}
                            setActiveHoverColor={setActiveHoverColor}
                            isHover={isHover}
                            setIsHover={setIsHover}
                            isTransitionInProgress={isTransitionInProgress}
                            setIsTransitionInProgress={setIsTransitionInProgress}
                        />
                    ))}
                </div>
            </div>
            {!breakpoints.sm && (
                <div className="homepageBrandFooter">
                    <div className="wrapText">
                        <h1>COLEGRAM</h1>
                        <h2>STUDIO DE CRÉATION VIDÉO ET DE CONTENU NARRATIF</h2>
                        <h3>160, ST-VIATEUR EST #508A MONTRÉAL, QUÉBEC H2T 1A8</h3>
                    </div>
                    <div className="wrapImage">
                        <StaticImage
                            src="../../images/colegram-animlogo9.png"
                            alt="Logo intro"
                            placeholder="none"
                            className="logoIntro_img"
                        />
                    </div>
                </div>
            )}
        </>
    );
};
