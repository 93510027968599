import * as React from 'react';
import { graphql } from 'gatsby';
import { VideoPlayer } from '../components/VideoPlayer';

// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type VideoPlayerSliceProps = {
    slice: any;
};

export const VideoPlayerSlice: React.FC<VideoPlayerSliceProps> = ({ slice }) => {
    const breakpoints = useBreakpoint();

    return (
        <div className={`wrapperVideoPlayerSlice container ${breakpoints.sm ? 'mobile' : ''}`}>
            <VideoPlayer videoUrl={slice.primary.video_url.text} isAutoplay={slice.primary.is_video_autoplay} />
            {slice.primary.video_caption.text && (
                <figcaption className="image-label">{slice.primary.video_caption.text}</figcaption>
            )}
        </div>
    );
};

export const query = graphql`
    fragment ProjectDataBodyVideoSlice on PrismicProjectDataBodyVideo {
        primary {
            video_url {
                text
            }
            video_caption {
                text
            }
            is_video_autoplay
        }
    }
`;
