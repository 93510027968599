import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, navigate, withPrefix } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Tween } from 'react-gsap';
import { gsap } from 'gsap';
import { useTopMenuControllerContext } from '../../provider/TopMenuContextProvider';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import logo from '../../images/panda.png';

type ProjectThumbnailProps = {
    project?: any;
    categories: any;
    setActiveHoverColor: any;
    isHover: boolean;
    setIsHover: any;
    isTransitionInProgress: boolean;
    setIsTransitionInProgress: any;
};

export const ProjectThumbnail: React.FC<ProjectThumbnailProps> = ({
    project,
    categories,
    setActiveHoverColor,
    isHover,
    setIsHover,
    isTransitionInProgress,
    setIsTransitionInProgress,
}) => {
    // const randomTime = Math.floor(Math.random() * 10) + 2;
    // const randomYposition = Math.floor(Math.random() * 100) + 10;
    const [isThumbnailsGifLoaded, setIsThumbnailsGifLoaded] = useState(false);
    const [isActiveProject, setIsActiveProject] = useState(false);
    const [showLoadingBar, setShowLoadingBar] = useState(false);
    const hoverImageRef = useRef<any>();
    const hoverInfoRef = useRef<any>();
    const hoverWrapper = useRef<any>();
    const thumbnails = useRef<any>();
    const { setWhiteHeader } = useTopMenuControllerContext();
    const breakpoints = useBreakpoint();
    // const [scrollY, setScrollY] = useState(window.scrollY);

    const mouseEnterActions = useCallback(() => {
        if (!isTransitionInProgress) {
            setIsHover(true);
            setIsActiveProject(true);
            setActiveHoverColor(project.data.project_color);
            setWhiteHeader(true);
            // document.body.style.overflow = 'hidden';
        }
    }, [isTransitionInProgress, project.data.project_color, setActiveHoverColor, setIsHover, setWhiteHeader]);

    const mouseLeaveActions = useCallback(() => {
        if (!isTransitionInProgress) {
            !isTransitionInProgress && setIsHover(false);
            !isTransitionInProgress && setIsActiveProject(false);
            !isTransitionInProgress && setActiveHoverColor('#ffffff');
            !isTransitionInProgress && setWhiteHeader(false);
            // document.body.style.overflow = 'visible';
        }
    }, [isTransitionInProgress, setActiveHoverColor, setIsHover, setWhiteHeader]);

    // const onMobileScroll = (el: any) => {
    //     if (breakpoints.sm && el > 0.3) {
    //         setIsHover(true);
    //         setIsActiveProject(true);
    //         setActiveHoverColor(project.data.project_color);
    //     }
    // };

    const onClick = useCallback(
        (url) => {
            setShowLoadingBar(true);
            setIsTransitionInProgress(true);
            gsap.to(hoverImageRef.current, { duration: 1, scale: 1.5, opacity: 0.7, ease: 'Power1.easeInOut' });
            gsap.to(hoverInfoRef.current, {
                delay: 0.2,
                duration: 0.5,
                scale: 2,
                y: -(hoverWrapper.current.clientHeight / 2 - hoverInfoRef.current.clientHeight / 2),
                ease: 'Power1.easeInOut',
            });
            gsap.to(hoverInfoRef.current, {
                duration: 2,
                onComplete: function () {
                    navigate(url);
                },
            });
            document.body.style.overflow = 'visible';
        },
        [setIsTransitionInProgress],
    );

    const projectCategoriesList = useMemo(() => {
        const projectCategoriesUID: any = [];
        const projectCategoriesName: any = [];

        project &&
            project.data?.project_categories.map((category: any) => {
                projectCategoriesUID.push(category.project_category.uid);
            });

        projectCategoriesUID.length > 0 &&
            projectCategoriesUID.map((catUid: any) => {
                const category = categories.nodes.filter((cat: any) => cat.uid === catUid);
                category && category[0] && projectCategoriesName.push(category[0].data.category_name.text);
            });

        return projectCategoriesName;
    }, [categories, project]);

    if (!project) return null;

    return (
        <div className="projectWrapper">
            {!breakpoints.sm && (
                // <Tween
                //     from={{ y: '0px' }}
                //     to={{ y: `${randomYposition}px` }}
                //     duration={randomTime}
                //     yoyo={true}
                //     repeat={-1}
                //     ease="Power4.easeInOut"
                // >
                <div
                    className={`projectThumbnail ${isHover ? 'isHover' : ''} ${isTransitionInProgress ? 'hide' : ''}`}
                    onMouseEnter={mouseEnterActions}
                    onMouseLeave={mouseLeaveActions}
                    onClick={() => {
                        onClick(project.url);
                    }}
                    ref={thumbnails}
                >
                    <Link to={project.url} className="projectThumbnailUrl">
                        <GatsbyImage
                            onLoad={() => {
                                setIsThumbnailsGifLoaded(true);
                            }}
                            onStartLoad={({ wasCached }) => {
                                wasCached && setIsThumbnailsGifLoaded(true);
                            }}
                            image={project.data?.project_thumbnail?.gatsbyImageData}
                            alt={'projectimg'}
                        />
                        {!isThumbnailsGifLoaded && (
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        )}
                        {/* <div
                            className="gifTest"
                            style={{
                                background: `url(${logo})`,
                            }}
                        /> */}
                    </Link>
                </div>
                // </Tween>
            )}
            {(isActiveProject || breakpoints.sm) && (
                // <Parallax
                //     onProgressChange={onMobileScroll}
                //     style={{ width: '100%' }}
                //     className={`projectHoverContentWrapper ${breakpoints.sm ? 'mobile' : ''}`}
                // >
                <div
                    className={`projectHoverContentWrapper ${breakpoints.sm ? 'mobile' : ''}`}
                    onClick={() => {
                        breakpoints.sm && navigate(project.url);
                    }}
                    style={{
                        backgroundColor: breakpoints.sm ? project.data.project_color : 'initial',
                    }}
                >
                    <div className="hoverContent" ref={hoverWrapper}>
                        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.6} ease="Power1.easeInOut">
                            <div ref={hoverImageRef}>
                                <GatsbyImage
                                    image={project.data?.project_thumbnail?.gatsbyImageData}
                                    alt={'projectimg'}
                                />
                            </div>
                            <div ref={hoverInfoRef}>
                                <h1>{project.data.title?.text}</h1>
                                <div className="categoriesWrapper">
                                    {projectCategoriesList.map((catName: any, index: number) =>
                                        projectCategoriesList.length === index + 1 ? (
                                            <h3 key={catName}>{catName}</h3>
                                        ) : (
                                            <h3 key={catName}>{catName}/</h3>
                                        ),
                                    )}
                                </div>
                            </div>
                        </Tween>
                    </div>
                    <div className={`progressBarWrapper ${showLoadingBar ? 'progressBarWrapperVisible' : ''}`}>
                        <div className="progress-bar">
                            <div className="progress-bar-value"></div>
                        </div>
                    </div>
                </div>
                // </Parallax>
            )}
        </div>
    );
};
