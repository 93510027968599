import { StaticImage } from 'gatsby-plugin-image';
import React, { Fragment } from 'react';
import { Timeline, Tween } from 'react-gsap';
import { useTopMenuControllerContext } from '../../provider/TopMenuContextProvider';

type IntroProps = {
    isVisible?: any;
};

export const Intro: React.FC<IntroProps> = ({ isVisible }) => {
    const { setIsIntroVisible } = useTopMenuControllerContext();

    return (
        <>
            {isVisible && (
                <Tween
                    from={{ top: 0 }}
                    to={{ top: '-4000px' }}
                    duration={2}
                    delay={2.5}
                    ease="Power4.easeInOut"
                    onComplete={() => {
                        console.log('onComplete');
                        setIsIntroVisible(false);
                    }}
                >
                    <div className="homepageIntro">
                        {/* <div
                            className="tempoAction"
                            onClick={() => {
                                setIsIntroVisible(false);
                            }}
                        ></div> */}
                        <div className="wrapperIntro">
                            <Timeline
                                target={
                                    <Fragment>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo1.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo2.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo3.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo4.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo5.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo6.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo7.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo8.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                        <div className="logoIntro">
                                            <StaticImage
                                                src="../../images/colegram-animlogo9.png"
                                                alt="Logo intro"
                                                placeholder="none"
                                                className="logoIntro_img"
                                            />
                                        </div>
                                    </Fragment>
                                }
                            >
                                <Tween
                                    from={{ opacity: 0 }}
                                    to={{ opacity: 1 }}
                                    delay={0.2}
                                    duration={0.125}
                                    target={0}
                                />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={1} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={2} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={3} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={4} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={5} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={6} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={7} />
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.125} target={8} />
                            </Timeline>
                        </div>
                    </div>
                </Tween>
            )}
        </>
    );
};
