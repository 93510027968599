import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { PrismicRichText, SliceZone } from '@prismicio/react';

import { Layout } from '../components/Layout';
import { components } from '../slices';
import { ProjectHeaderFullScreen } from '../components/ProjectHeaderFullScreen';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Tween } from 'react-gsap';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTopMenuControllerContext } from '../provider/TopMenuContextProvider';

type ProjectTemplateProps = {
    data?: any;
};

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({ data }) => {
    const breakpoints = useBreakpoint();
    const projectContent = data.prismicProject;
    const project = projectContent.data || {};

    const { lang, type, url } = projectContent;
    const alternateLanguages = project.alternate_languages || [];
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    };

    const topMenu = data.prismicTopMenu || {};
    const projectsData = data.allPrismicProject || {};
    const title = project.title.text || 'Untitled';
    const [windowWidth, setWindowWidth] = useState(0);
    const [documentHeight, setDocumentHeight] = useState(0);
    const { setWhiteHeader } = useTopMenuControllerContext();

    useEffect(() => {
        if (typeof window !== 'undefined' && document) {
            setWindowWidth(window.innerWidth);
            setDocumentHeight(document.documentElement.offsetHeight);
            setWhiteHeader(false);
        }
    }, []);

    const parallaxRandomPositions = [
        ['0px', '0px'],
        ['-500px', '500px'],
        [windowWidth + 'px', Math.floor(Math.random() * 100) + 50 + 'px'],
    ];

    if (!data) return null;

    return (
        <Layout topMenu={topMenu.data} projectsData={projectsData} activeDocMeta={activeDoc} forceWhiteHeader={false}>
            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={2} ease="Power1.easeInOut">
                <div
                    className="pageWrapper"
                    // style={{
                    //     backgroundColor: project.project_color ? `${project.project_color}19` : '#fff',
                    // }}
                >
                    <div className={`projectHeaderWrapper ${breakpoints.sm ? 'mobile' : ''}`}>
                        <ProjectHeaderFullScreen
                            videoUrl={project.project_video_link.text}
                            fullScreenImage={project.project_header_image}
                        />
                        {/* <Parallax speed={-12}> */}
                        <div className="projectInfoWrapper">
                            <h1>{title}</h1>
                            {project.project_description.richText.length > 0 && (
                                <div className="machinaText">
                                    <PrismicRichText field={project.project_description.richText} />
                                </div>
                            )}
                        </div>
                        {/* </Parallax> */}
                    </div>
                    {!breakpoints.sm &&
                        project.parallax_images.map((image: any, index: number) => {
                            const randomPosition = Math.floor(Math.random() * parallaxRandomPositions.length);
                            return (
                                <Parallax
                                    speed={Math.floor(Math.random() * 100) + 60}
                                    translateX={parallaxRandomPositions[randomPosition] as any}
                                    className={'paralaxElementWrapper'}
                                    style={{ top: Math.floor(Math.random() * (documentHeight - 1000)) }}
                                    key={'parallaxImage' + index}
                                    onChange={(el: any) => {
                                        if (el.progress && el.progress < 0.2 && el.progres !== 1) {
                                            document.getElementById('parallaxImage' + index)!.style.opacity = '1';
                                        }
                                    }}
                                >
                                    <div className={`item`} id={'parallaxImage' + index}>
                                        <GatsbyImage
                                            image={image.parallax_image.gatsbyImageData}
                                            alt={image.parallax_image.alt ? image.parallax_image.alt : ''}
                                        />
                                    </div>
                                </Parallax>
                            );
                        })}
                    <div className="mediasWrapper">
                        <SliceZone slices={project.body} components={components} />
                    </div>
                </div>
            </Tween>
        </Layout>
    );
};

export const query = graphql`
    query ProjectQuery($id: String, $lang: String) {
        prismicProject(id: { eq: $id }) {
            _previewable
            id
            uid
            lang
            type
            url
            data {
                date
                title {
                    text
                }
                project_color
                project_thumbnail {
                    gatsbyImageData
                    alt
                    copyright
                }
                project_video_link {
                    text
                }
                project_header_image {
                    gatsbyImageData
                    alt
                    copyright
                }
                project_description {
                    richText
                }
                parallax_images {
                    parallax_image {
                        gatsbyImageData
                        alt
                        copyright
                    }
                }
                body {
                    ... on PrismicSliceType {
                        id
                        slice_label
                        slice_type
                    }
                    ...ProjectDataBodyImageWithCaption
                    ...ProjectDataBodyVideoSlice
                    ...ProjectDataBodyText
                    ...ProjectDataCreditsList
                    ...ProjectDataImagesGrid
                    ...ProjectDataImagesSlideshow
                }
            }
        }
        prismicTopMenu(lang: { eq: $lang }) {
            ...TopMenuFragment
        }
        allPrismicProject(sort: { fields: data___date, order: DESC }, filter: { lang: { eq: $lang } }) {
            nodes {
                id
                uid
                url
                data {
                    title {
                        text
                    }
                    project_color
                    project_thumbnail {
                        gatsbyImageData
                        alt
                        copyright
                    }
                }
            }
        }
    }
`;

export default withPrismicPreview(ProjectTemplate as any);
// export default PageTemplate;
