import { FullWidthImage } from './FullWidthImage';
import { VideoPlayerSlice } from './VideoPlayerSlice';
import { ImageCaption } from './ImageCaption';
import { TextBloc } from './TextBloc';
import { ProjectCreditsList } from './ProjectCreditsList';
import { ImagesGrid } from './ImagesGrid';
import { ImagesSlideShow } from './ImagesSlideShow';

export const components = {
    full_width_image: FullWidthImage,
    video: VideoPlayerSlice,
    image_with_caption: ImageCaption,
    text: TextBloc,
    credits_list: ProjectCreditsList,
    images_grid: ImagesGrid,
    images_slideshow: ImagesSlideShow,
};
