import React from 'react';
import { VideoPlayer } from './VideoPlayer';

type ProjectHeaderFullScreenProps = {
    videoUrl: string;
    fullScreenImage: any;
};

export const ProjectHeaderFullScreen: React.FC<ProjectHeaderFullScreenProps> = ({ videoUrl, fullScreenImage }) => {
    return (
        <div className={'projectHeaderFullScreenWrapper'}>
            <VideoPlayer videoUrl={videoUrl} fullScreenImage={fullScreenImage} isHeaderVideo={true} />
        </div>
    );
};
