import * as React from 'react';
import { graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Default Image
const DefaultImage = ({ slice }: any) => (
    <div className="post-image container">
        <figcaption className="block-img">
            <img src={slice.primary.image.url} alt={slice.primary.image.alt} />
            {slice.primary.caption.text && (
                <figcaption className="image-label">{slice.primary.caption.text}</figcaption>
            )}
        </figcaption>
    </div>
);

// Emphasized Image
const EmphasizedImage = ({ slice }: any) => (
    <div className="post-image container">
        <figcaption className="block-img emphasized">
            <img src={slice.primary.image.url} alt={slice.primary.image.alt} />
            {slice.primary.caption.text && (
                <figcaption className="image-label">{slice.primary.caption.text}</figcaption>
            )}
        </figcaption>
    </div>
);

// Full Width Image
const FullWidthImage = ({ slice }: any) => (
    <div className="post-image full-width-image" style={{ backgroundImage: `url(${slice.primary.image.url})` }}>
        <div className="wrapper">
            {slice.primary.caption.text && <span className="image-label">{slice.primary.caption.text}</span>}
        </div>
    </div>
);

// Function to determine the image type
const renderSwitch = (slice: any) => {
    switch (slice.slice_label) {
        case 'image-full-width':
            return <FullWidthImage slice={slice} />;
        case 'emphasized':
            return <EmphasizedImage slice={slice} />;
        default:
            return <DefaultImage slice={slice} />;
    }
};

type ImageCaptionProps = {
    slice: any;
};

export const ImageCaption: React.FC<ImageCaptionProps> = ({ slice }) => {
    const breakpoints = useBreakpoint();

    // return <Parallax speed={breakpoints.sm ? 0 : -4}>{renderSwitch(slice)}</Parallax>;
    return <>{renderSwitch(slice)}</>;
};

export const query = graphql`
    fragment ProjectDataBodyImageWithCaption on PrismicProjectDataBodyImageWithCaption {
        primary {
            image {
                alt
                url
            }
            caption {
                text
            }
        }
    }
`;
