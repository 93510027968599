import * as React from 'react';
// import twitterIcon from '../images/twitter.png';
// import instagramIcon from '../images/instagram.png';
// import facebookIcon from '../images/facebook.png';

export const Footer: React.FC = () => {
    return (
        <footer>
            <p>Colegram {new Date().getFullYear()}</p>
        </footer>
    );
};
