import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type VideoPlayerProps = {
    videoUrl: string;
    fullScreenImage?: any;
    isHeaderVideo?: any;
    isAutoplay?: boolean;
};

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, fullScreenImage, isHeaderVideo, isAutoplay }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const breakpoints = useBreakpoint();

    const handlePlay = () => {
        isPlaying ? setIsPlaying(false) : setIsPlaying(true);
    };

    const startVideo = () => {
        !isHeaderVideo && !breakpoints.sm && isAutoplay && setIsPlaying(true);
    };

    const pauseVideo = () => {
        setIsPlaying(false);
    };

    return (
        <Parallax onEnter={startVideo} onExit={pauseVideo} style={{ width: '100%' }}>
            <div className={`sliceVideoPlayer ${breakpoints.sm ? 'mobile' : ''}`}>
                {fullScreenImage && fullScreenImage.gatsbyImageData && (
                    <div className={`videoThumbnailsOverlay ${isPlaying ? 'hideVideoThumbnailsOverlay' : ''}`}>
                        {!isPlaying ? (
                            <GatsbyImage image={fullScreenImage?.gatsbyImageData} alt={'Thumbnail video image'} />
                        ) : (
                            <></>
                        )}
                    </div>
                )}

                <div className="playerWrapper">
                    <ReactPlayer
                        url={videoUrl}
                        width={'100%'}
                        height={'auto'}
                        loop={true}
                        volume={1}
                        controls={true}
                        muted={true}
                        playing={isPlaying}
                        className={'react-player'}
                    />
                </div>
                {!isPlaying && (
                    <Parallax className={'ParallaxWrapper'} speed={breakpoints.sm ? 0 : -10}>
                        <div className="playButton" onClick={handlePlay}>
                            <p>Play</p>
                        </div>
                    </Parallax>
                )}
            </div>
        </Parallax>
    );
};
