import React, { useContext, useState, Dispatch, SetStateAction } from 'react';

export interface ContextProps {
    isWhiteHeader: boolean;
    setWhiteHeader: Dispatch<SetStateAction<boolean>>;
    isIntroVisible: boolean;
    setIsIntroVisible: Dispatch<SetStateAction<boolean>>;
}

export const InitialState: ContextProps = {
    isWhiteHeader: false,
    setWhiteHeader: () => {},
    isIntroVisible: false,
    setIsIntroVisible: () => {},
};

export const TopMenuControllerContext = React.createContext<ContextProps>(InitialState);

export const TopMenuContextProvider: React.FC = ({ children }) => {
    const [isWhiteHeader, setWhiteHeader] = useState(false);
    const [isIntroVisible, setIsIntroVisible] = useState(true);

    return (
        <TopMenuControllerContext.Provider
            value={{
                isWhiteHeader,
                setWhiteHeader,
                isIntroVisible,
                setIsIntroVisible,
            }}
        >
            {children}
        </TopMenuControllerContext.Provider>
    );
};

export const useTopMenuControllerContext = (): ContextProps => useContext<ContextProps>(TopMenuControllerContext);
