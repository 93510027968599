import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { PrismicLink, PrismicText } from '@prismicio/react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import { LanguageSwitcher } from './LanguageSwitcher';
import { useEffect, useState } from 'react';
import { TopMenuListProject } from './TopMenuListProject';
import { useTopMenuControllerContext } from '../provider/TopMenuContextProvider';
import { Tween } from 'react-gsap';
import ReactPlayer from 'react-player';
import { Intro } from './homepage/Intro';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type TopMenuProps = {
    topMenu: any;
    projectsData: any;
    activeDocMeta: any;
    forceWhiteHeader?: boolean;
};

export const TopMenu: React.FC<TopMenuProps> = ({ topMenu, projectsData, forceWhiteHeader }) => {
    const [textProjectsList, setTextProjectsList] = useState([]);
    const [isIndexOpen, setIsIndexOpen] = useState(false);
    const [isDemoOpen, setIsDemoOpen] = useState(false);
    const { isWhiteHeader, setWhiteHeader, isIntroVisible } = useTopMenuControllerContext();
    const [activeHoverColor, setActiveHoverColor] = useState('#ffffff');
    const [activeBackgroundImage, setActiveBackgroundImage] = useState();
    const [activeTitle, setActiveTitle] = useState('');
    const breakpoints = useBreakpoint();

    const renderedMenuLinks = topMenu
        ? topMenu.menu_links.map((menuLink: any, index: any) => {
              return (
                  <div className="menuItem" key={`top-nav-${index}`}>
                      <PrismicLink href={menuLink.link.url}>
                          <p>
                              <PrismicText field={menuLink.label.richText} />
                          </p>
                      </PrismicLink>
                  </div>
              );
          })
        : null;

    useEffect(() => {
        const textFeaturedProjectsUid: any = [];
        const textFeaturedProjectsList: any = [];

        topMenu.index_projects_list.map((project: any) => {
            textFeaturedProjectsUid.push(project.index_project.uid);
        });

        textFeaturedProjectsUid.length > 0 &&
            textFeaturedProjectsUid.map((projectUid: any) => {
                const project = projectsData.nodes.filter((project: any) => project.uid === projectUid);
                textFeaturedProjectsList.push(project[0]);
            });

        setTextProjectsList(textFeaturedProjectsList);
        setActiveBackgroundImage(textFeaturedProjectsList[0].data?.project_thumbnail?.gatsbyImageData);
        setActiveHoverColor(textFeaturedProjectsList[0].data?.project_color);
        setActiveTitle(textFeaturedProjectsList[0].data?.title.text);
    }, [projectsData, topMenu.index_projects_list, topMenu.index_projects_list.index_project]);

    const openIndex = () => {
        if (!breakpoints.sm) {
            !isIndexOpen || forceWhiteHeader ? setWhiteHeader(true) : setWhiteHeader(false);
        } else {
            const url = typeof window !== 'undefined' ? window.location.href : '';
            !isIndexOpen || !forceWhiteHeader || url.includes('project') || url.includes('page')
                ? setWhiteHeader(false)
                : setWhiteHeader(true);
            if (!isIndexOpen) {
                setWhiteHeader(false);
            } else {
                forceWhiteHeader || url.includes('project') || url.includes('page')
                    ? setWhiteHeader(false)
                    : setWhiteHeader(true);
            }
        }
        !isIndexOpen ? setIsIndexOpen(true) : setIsIndexOpen(false);
    };

    useEffect(() => {
        forceWhiteHeader && setWhiteHeader(true);
    }, [forceWhiteHeader, setWhiteHeader]);

    useEffect(() => {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        breakpoints.sm && !url.includes('project') && !url.includes('page') && setWhiteHeader(true);
    }, [breakpoints.sm, setWhiteHeader]);

    const openDemoVideo = () => {
        !isDemoOpen ? setIsDemoOpen(true) : setIsDemoOpen(false);
        !isDemoOpen ? setWhiteHeader(true) : setWhiteHeader(false);
    };

    return (
        <>
            <Intro isVisible={isIntroVisible} />
            <header className={`${!breakpoints.sm && isIndexOpen ? 'fixHeader' : ''}`}>
                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.6} ease="Power1.easeInOut">
                    <div className="headerLogo">
                        <Link to="/">
                            {isWhiteHeader ? (
                                // <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.6} ease="Power1.easeInOut">
                                <StaticImage
                                    src="../images/logoColegram.png"
                                    alt="Site logo"
                                    placeholder="none"
                                    className="logo"
                                />
                            ) : (
                                // </Tween>
                                // <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.6} ease="Power1.easeInOut">
                                <StaticImage
                                    src="../images/logoColegramBleu.png"
                                    alt="Site logo"
                                    placeholder="none"
                                    className="logo"
                                />
                                // </Tween>
                            )}
                        </Link>
                    </div>
                </Tween>
                <div
                    className={`indexWrapper ${isIndexOpen ? 'open' : ''}`}
                    style={{
                        backgroundColor: activeHoverColor && !breakpoints.sm ? activeHoverColor : '#fff',
                    }}
                >
                    {!breakpoints.sm && (
                        <div className="menuWrapper">
                            <div className="wrapperPageMenu">{renderedMenuLinks}</div>
                            <div className="wrapperProjectMenu">
                                <div className="menuItem" key={`top-nav-projects`}>
                                    <p>{topMenu.projects_title.text}</p>
                                </div>
                            </div>
                            <div className="wrapperDemoMenu">
                                <div className="menuItem" key={`top-nav-demo`} onClick={openDemoVideo}>
                                    <p>{topMenu.demo_title.text}</p>
                                </div>
                            </div>
                            {/* <LanguageSwitcher activeDocMeta={activeDocMeta} /> */}
                        </div>
                    )}
                    {textProjectsList && activeBackgroundImage && (
                        <div className="listProjectHoverEffect">
                            <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.6} ease="Power1.easeInOut">
                                <div>
                                    <GatsbyImage image={activeBackgroundImage} alt={'projectimg'} />
                                </div>
                            </Tween>
                        </div>
                    )}
                    <div className="projectsListWrapper">
                        {/* {textProjectsList && activeBackgroundImage && (
                            <div className="listProjectHoverEffect">
                                <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} duration={0.6} ease="Power1.easeInOut">
                                    <div>
                                        <GatsbyImage image={activeBackgroundImage} alt={'projectimg'} />
                                    </div>
                                </Tween>
                            </div>
                        )} */}
                        {textProjectsList &&
                            textProjectsList.map((project: any) => (
                                <TopMenuListProject
                                    project={project}
                                    setActiveHoverColor={setActiveHoverColor}
                                    setActiveBackgroundImage={setActiveBackgroundImage}
                                    activeTitle={activeTitle}
                                    setActiveTitle={setActiveTitle}
                                    key={project.id}
                                />
                            ))}
                    </div>
                    {breakpoints.sm && (
                        <div className="menuWrapper">
                            <div className="wrapperPageMenu">{renderedMenuLinks}</div>
                            {!breakpoints.sm && (
                                <div className="wrapperProjectMenu">
                                    <div className="menuItem" key={`top-nav-projects`}>
                                        <p>{topMenu.projects_title.text}</p>
                                    </div>
                                </div>
                            )}
                            <div className="wrapperDemoMenu">
                                <div className="menuItem" key={`top-nav-demo`} onClick={openDemoVideo}>
                                    <p>{topMenu.demo_title.text}</p>
                                </div>
                            </div>
                            {/* <LanguageSwitcher activeDocMeta={activeDocMeta} /> */}
                        </div>
                    )}
                </div>
                <div className="burgerMenu" onClick={openIndex}>
                    <div className={`burger ${isIndexOpen ? 'open' : ''} ${isWhiteHeader ? 'white' : ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                {isDemoOpen && (
                    <div className="demoPlayer" onClick={openDemoVideo}>
                        <div className="burger open white">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ReactPlayer
                            url={topMenu.demo_link.text}
                            width={'100%'}
                            height={'100%'}
                            loop={true}
                            volume={1}
                            controls={true}
                            muted={true}
                            playing={true}
                        />
                    </div>
                )}
            </header>
        </>
    );
};

export const query = graphql`
    fragment TopMenuFragment on PrismicTopMenu {
        _previewable
        type
        lang
        data {
            menu_links {
                label {
                    richText
                    html
                    text
                }
                link {
                    id
                    url
                }
            }
            facebook_link {
                url
            }
            vimeo_link {
                url
            }
            instagram_link {
                url
            }
            projects_title {
                text
            }
            demo_title {
                text
            }
            demo_link {
                text
            }
            index_projects_list {
                index_project {
                    id
                    uid
                    url
                }
            }
        }
    }
`;
