import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { TopMenu } from './TopMenu';
import { Footer } from './Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import { TopMenuContextProvider } from '../provider/TopMenuContextProvider';
// @ts-ignore
import metaImage from '../images/colegram-animlogo9.png';

type LayoutProps = {
    children: any;
    topMenu: any;
    projectsData: any;
    activeDocMeta: any;
    forceWhiteHeader?: boolean;
};

export const Layout: React.FC<LayoutProps> = ({ children, topMenu, projectsData, activeDocMeta, forceWhiteHeader }) => {
    const queryData = useStaticQuery(graphql`
        query SiteQuery {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);

    const url = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{queryData.site.siteMetadata.title}</title>
                <meta name="description" content={queryData.site.siteMetadata.description} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta property="og:image" content={metaImage} />
                <meta property="og:url" content="https://www.colegram.com" />
                <meta property="og:site_name" content={queryData.site.siteMetadata.title} />
                <link
                    href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
                    rel="stylesheet"
                    type="text/css"
                />
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;1,100;1,200;1,300;1,400;1,700&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <ParallaxProvider>
                {/* <TopMenuContextProvider> */}
                <TopMenu
                    topMenu={topMenu}
                    projectsData={projectsData}
                    activeDocMeta={activeDocMeta}
                    forceWhiteHeader={forceWhiteHeader}
                />
                <main>{children}</main>
                {url !== '/' && <Footer />}
                {/* </TopMenuContextProvider> */}
            </ParallaxProvider>
        </>
    );
};
