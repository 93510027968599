import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type TextProps = {
    slice: any;
};

export const TextBloc: React.FC<TextProps> = ({ slice }) => {
    const breakpoints = useBreakpoint();

    const isImagesBlocs = slice.primary.text.richText.find((bloc) => bloc.type === 'image');
    return (
        <Parallax speed={breakpoints.sm ? 0 : -5} className={'wrapper'}>
            <div
                className={`sliceTextBloc container ${breakpoints.sm ? 'mobile' : ''} ${
                    isImagesBlocs ? 'isImagesBlocs' : ''
                }`}
            >
                <div
                    className={`machinaText ${slice.primary.align_center ? 'alignCenter' : ''} ${
                        slice.primary.special_font ? 'specialFont' : ''
                    }`}
                >
                    <PrismicRichText field={slice.primary.text.richText} />
                </div>
            </div>
        </Parallax>
    );
};

export const query = graphql`
    fragment ProjectDataBodyText on PrismicProjectDataBodyText {
        primary {
            text {
                richText
            }
            special_font
        }
    }

    fragment PageDataBodyText on PrismicPageDataBodyText {
        primary {
            text {
                richText
            }
            special_font
            align_center
        }
    }
`;
