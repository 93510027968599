import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type ProjectCreditsListProps = {
    slice: any;
};

export const ProjectCreditsList: React.FC<ProjectCreditsListProps> = ({ slice }) => {
    const breakpoints = useBreakpoint();

    return (
        <div className={`sliceProjectCreditsList container ${breakpoints.sm ? 'mobile' : ''}`}>
            {/* <Parallax className="creditWrapperParallax" speed={!breakpoints.sm ? -12 : -2}> */}
            <div className="creditWrapper">
                <h3>{slice.primary.credits_section_title.text}</h3>
                <div className="creditItems">
                    {slice.items.map((item: any, index: number) => (
                        <div className="creditItem" key={'item_' + index}>
                            <div className="category">
                                <PrismicRichText field={item.credit_title.richText} />
                            </div>
                            <div className="names">
                                <PrismicRichText field={item.credit_name.richText} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* </Parallax> */}
        </div>
    );
};

export const query = graphql`
    fragment ProjectDataCreditsList on PrismicProjectDataBodyCreditsList {
        primary {
            credits_section_title {
                text
            }
        }
        items {
            credit_title {
                richText
            }
            credit_name {
                richText
            }
        }
    }
`;
