import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import { Projects } from '../components/homepage/Projects';
import { useTopMenuControllerContext } from '../provider/TopMenuContextProvider';
import { Tween } from 'react-gsap';

type HomepageTemplateProps = {
    data?: {
        prismicHomepage?: any;
        prismicTopMenu?: any;
        allPrismicProject?: any;
        allPrismicCategories?: any;
    };
};

const HomepageTemplate: React.FC<HomepageTemplateProps> = ({ data }) => {
    const [gridProjectsList, setGridProjectsList] = useState([]);
    const [isIntroFinish, setIsIntroFinish] = useState(false);
    const { isIntroVisible } = useTopMenuControllerContext();

    const homepage = data?.prismicHomepage || {};
    const topMenu = data?.prismicTopMenu || {};
    const projects = data?.allPrismicProject || {};
    const categories = data?.allPrismicCategories || {};

    const { lang, type, url } = homepage || {};
    const alternateLanguages = homepage.alternate_languages || [];
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    };

    useEffect(() => {
        const gridFeaturedProjectsUid: any = [];
        const gridfeaturedProjectsList: any = [];

        //Create the Grid list of featured projects UID with order
        homepage.data.grid_featured_projects.map((project: any) => {
            gridFeaturedProjectsUid.push(project.grid_featured_project.uid);
        });

        //Loop into all projects and sort all Featured one releated to the list above
        gridFeaturedProjectsUid.length > 0 &&
            gridFeaturedProjectsUid.map((projectUid: any) => {
                const project = projects.nodes.filter((project: any) => project.uid === projectUid);
                project && gridfeaturedProjectsList.push(project[0]);
            });

        setGridProjectsList(gridfeaturedProjectsList);
    }, [homepage.data, projects.nodes]);

    if (!data) return null;

    return (
        <Layout topMenu={topMenu.data} projectsData={projects} activeDocMeta={activeDoc}>
            {/* {!isIntroVisible && ( */}
            <Tween
                from={{ opacity: 1 }}
                to={{ opacity: 0 }}
                delay={isIntroVisible ? 3 : 0.5}
                duration={1}
                ease="Power4.easeInOut"
                onComplete={() => {
                    setIsIntroFinish(true);
                }}
            >
                <div className={`whiteSplash ${isIntroFinish ? 'hideWhiteSplash' : ''}`}></div>
            </Tween>
            {/* )} */}
            <Projects
                data={gridProjectsList.length > 0 ? gridProjectsList : projects.nodes}
                categories={categories}
                homepageData={homepage.data}
            />
        </Layout>
    );
};

export const query = graphql`
    query homepageQuery($lang: String) {
        prismicHomepage(lang: { eq: $lang }) {
            _previewable
            alternate_languages {
                uid
                type
                lang
            }
            lang
            url
            type
            data {
                display_title {
                    text
                }
                grid_featured_projects {
                    grid_featured_project {
                        id
                        uid
                        url
                    }
                }
                homepage_description {
                    richText
                }
                body {
                    ... on PrismicSliceType {
                        id
                        slice_type
                        slice_label
                    }
                    ...HomepageDataBodyFullWidthImage
                }
            }
        }
        prismicTopMenu(lang: { eq: $lang }) {
            ...TopMenuFragment
        }
        allPrismicProject(sort: { fields: data___date, order: DESC }, filter: { lang: { eq: $lang } }) {
            nodes {
                _previewable
                alternate_languages {
                    uid
                    type
                    lang
                }
                lang
                id
                uid
                url
                tags
                data {
                    title {
                        text
                    }
                    date(formatString: "MMM D, YYYY")
                    project_thumbnail {
                        gatsbyImageData
                        alt
                        copyright
                    }
                    project_color
                    project_categories {
                        project_category {
                            id
                            uid
                        }
                    }
                    body {
                        ... on PrismicProjectDataBodyText {
                            id
                            slice_label
                            slice_type
                            primary {
                                text {
                                    richText
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicCategories(filter: { lang: { eq: $lang } }) {
            nodes {
                _previewable
                alternate_languages {
                    uid
                    type
                    lang
                }
                lang
                id
                uid
                url
                tags
                data {
                    category_name {
                        text
                    }
                    featured_projects {
                        featured_project {
                            id
                            uid
                            url
                        }
                    }
                }
            }
        }
    }
`;

export default withPrismicPreview(HomepageTemplate as any);
